import React, {Component, createRef} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dropzone from 'react-dropzone';
import {uuidv4} from "./utils/uuid";
import firebase from 'firebase/app';
import 'firebase/storage';
import LinearProgress from '@material-ui/core/LinearProgress';
import Hidden from '@material-ui/core/Hidden';
import {ImageQualityCheck, supportedImageTypes} from './utils/ImageQualityCheck';
import {CountWords} from './utils/CountWords';
import {PhotoGuidelines} from './utils/PhotoGuidelines';
import AdSizeSelector from './utils/AdSizeSelector';
import {Document, Page} from 'react-pdf';
import {pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const dropzoneRef = createRef();

class Personalization extends Component {
    constructor(props) {
        super(props);
        this.classes = props;

        let sd = this.props.submissionData,
            count = CountWords(sd.text);

        this.state = {
            size: sd.size,
            text: sd.text,
            words: count,
            images: sd.images,
            specialInstructions: sd.specialInstructions,
            imageFiles: this.props.imageFiles,
            imageSrcToShow: this.props.imageSrcToShow,
            imageProgress: {},
            imageErrors: {},
            imageWarnings: {},
            error: {
                text: false,
                photos: false,
            },
            showErrors: false,
        };

        if (this.state.text === '') {
            this.state.text = `We're so proud of you, ${this.props.submissionData.studentName}!

Love,
${this.props.submissionData.purchaserName}`;
            this.state.words = CountWords(this.state.text);
        }
    }

    selectSize(key) {
        this.setState({
            size: key,
        }, this.validate);
    }

    handleChange = name => event => {
        if (name === 'text') {
            this.setState({
                [name]: event.target.value,
                words: CountWords(event.target.value),
            }, this.validate);
        } else {
            this.setState({
                [name]: event.target.value,
            }, this.validate);
        }
    };

    handleSiChange = index => event => {
        this.setState({
            specialInstructions: {
                ...this.state.specialInstructions,
                [index]: event.target.value,
            },
        });
    };

    onDrop(images) {
        let stateImages = this.state.images,
            stateImageFiles = this.state.imageFiles,
            stateImageSrcToShow = this.state.imageSrcToShow;

        images.forEach(file => {
            let fileUUID = uuidv4().toString(),
                fileName = (fileUUID).toLowerCase();

            stateImages.push(fileName);
            stateImageFiles[fileName] = file;

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                stateImageSrcToShow[fileName] = reader.result;
            });
            reader.readAsDataURL(file);

            this.setState({
                imageProgress: {
                    ...this.state.imageProgress,
                    [fileName]: 0,
                },
            });

            // Check that the MIME type is okay.
            if (!supportedImageTypes.includes(file.type)) {
                console.error('Image type not accepted: ' + file.type);
                return;
            }

            // Do the firebase storage
            let imageRef = this.props.imageStorageRef.child(fileName),
                uploadTask = imageRef.put(file);

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                snapshot => {
                    // Progress

                    this.setState({
                        imageProgress: {
                            ...this.state.imageProgress,
                            [fileName]: parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                        },
                    });
                },
                error => {
                    console.error('Upload failed: ' + error.code);

                    this.setState({
                        imageErrors: {
                            ...this.state.imageErrors,
                            [fileName]: 'Upload failed. Please remove and try again.',
                        },
                    });
                },
                () => {
                    console.info('Upload completed');

                    this.setState({
                        imageProgress: {
                            ...this.state.imageProgress,
                            [fileName]: 100,
                        },
                    }, this.validate);
                });
        });

        this.setState({
            images: stateImages,
            imageFiles: stateImageFiles,
            imageSrcToShow: stateImageSrcToShow,
        }, this.validate);
    }

    removeImage(key) {
        let images = this.state.images,
            imageFiles = this.state.imageFiles,
            si = this.state.specialInstructions,
            imageErrors = this.state.imageErrors,
            imageProgress = this.state.imageProgress;

        delete si[key];
        delete imageFiles[key];
        delete imageErrors[key];
        delete imageProgress[key];

        let indexOf = images.indexOf(key);
        images.splice(indexOf, 1);

        this.setState({
            images: images,
            imageFiles: imageFiles,
            specialInstructions: si,
            imageErrors: imageErrors,
            imageProgress: imageProgress,
        }, this.validate);
    }

    addSpecialInstructions(index) {
        let si = this.state.specialInstructions;
        si[index] = '';

        this.setState({
            specialInstructions: si,
        });
    }

    update() {
        this.props.updateImageFiles(
            this.state.imageFiles,
            this.state.imageSrcToShow);
        this.props.updateSub({
            size: this.state.size,
            text: this.state.text,
            images: this.state.images,
            specialInstructions: this.state.specialInstructions,
        });
    }

    hasError() {
        return (this.state.error.text !== false)
            || (this.state.error.photos !== false);
    }

    validate() {
        // What do we need to check?
        // That there are not too many words.
        // That there are not too few words.
        // That there are not too many photos.
        // That there are not too few photos.

        let error = {
                text: false,
                photos: false,
            },
            stateImageErrors = this.state.imageErrors,
            stateImageWarnings = this.state.imageWarnings;

        // Words errors
        if (this.state.words < 1) {
            error.text = 'Please enter a message.';
        } else if (this.state.words > this.props.sdata[this.state.size].words) {
            error.text = 'Your message exceeds the word count. ' +
                'Please choose a larger ad size or shorten your message.';
        }

        // Photos errors
        let photos = this.state.images.length;
        if (photos < 1) {
            error.photos = 'Please upload at least 1 photo.';
        } else if (photos > this.props.sdata[this.state.size].photos) {
            let excess = photos - this.props.sdata[this.state.size].photos;
            error.photos = 'Too many photos. ' +
                'Please choose a larger ad size or remove ' + excess + ' photos.';
        }

        let uploading = Object.keys(this.state.imageProgress)
            .reduce((acc, val) => acc || this.state.imageProgress[val] !== 100, false);
        if (uploading) {
            error.photos = 'A photo is still uploading. Please wait.';
        }

        // Photo-specific quality problems
        Object.keys(this.state.imageFiles).forEach(name => {
            let result = ImageQualityCheck(this.state.imageFiles[name]);

            if (!(name in Object.keys(this.state.imageErrors)) && result.error) {
                // Don't add an error if there already is one.
                stateImageErrors[name] = result.error;
            }

            if (result.warning) {
                stateImageWarnings[name] = result.warning;
            }
        });

        // Photo-specific upload errors
        if (Object.keys(this.state.imageErrors).length > 0) {
            error.photos = 'Some photos have errors. Please resolve them before continuing.';
        }

        this.setState({
            error: error,
            imageErrors: stateImageErrors,
            imageWarnings: stateImageWarnings,
        });

        return (error.text === false)
            && (error.photos === false);
    }

    next() {
        this.setState({
            showErrors: true,
        });

        this.update();

        if (this.validate()) {
            this.props.nextCard();
        }
    }

    last() {
        this.update();

        let error = this.state.error,
            okay = true;

        if (error === undefined)
            error = {};

        let uploading = Object.keys(this.state.imageProgress)
            .reduce((acc, val) => acc || this.state.imageProgress[val] !== 100, false);
        if (uploading) {
            error.photos = 'A photo is still uploading. Please wait.';
            okay = false;

            this.setState({
                showErrors: true,
                error: error,
            });
        }

        if (okay)
            this.props.lastCard();
    }

    render() {
        return (
            <CardContent id='personalization'
                         style={{maxWidth: 550}}>

                <AdSizeSelector
                    sdata={this.props.sdata}
                    selectSize={(key) => this.selectSize(key)}
                    explain={true}
                    size={this.state.size}
                />

                <br/>

                <Hidden smUp>
                    <hr/>
                </Hidden>

                <br/>
                <br/>

                <form noValidate
                      autoComplete='off'>

                    <Grid container
                          alignItems='stretch'
                          direction='column'
                          justify='space-around'>

                        {/* Message Box Section */}
                        <Hidden smUp>
                            {this.messageBoxSection('column')}
                        </Hidden>
                        <Hidden xsDown>
                            {this.messageBoxSection('row')}
                        </Hidden>

                        <Hidden smUp>
                            <hr/>
                            <br/>
                        </Hidden>

                        <br/>

                        {/* Image Upload Section */}
                        <Hidden smUp>
                            {this.imageUploadSection('column')}
                        </Hidden>
                        <Hidden xsDown>
                            {this.imageUploadSection('row')}
                        </Hidden>

                    </Grid>

                </form>

                <br/>
                <br/>

                <Grid
                    // xs={12}
                    container
                    alignItems='center'
                    direction='row'
                    justify='space-between'
                >
                    <Button variant='contained'
                            onClick={() => this.last()}>
                        Back
                    </Button>
                    <Button variant='contained'
                            color='primary'
                            onClick={() => this.next()}
                            id='next'>
                        Next
                    </Button>
                </Grid>

                <div style={{paddingTop: 30}}>
                    <PhotoGuidelines/>
                </div>
            </CardContent>
        );
    }

    messageBoxSection(direction) {
        return <Grid container
                     direction={direction}
                     justify='space-between'>

            {/* If this is NOT XS, show the status on the left */}
            <Hidden xsDown>
                <Grid item sm={2}>
                    <Typography variant='body2'
                                align='right'
                                color={(this.state.showErrors === false
                                    || this.state.error.text === false)
                                    ? 'initial' : 'error'}>
                        Message
                    </Typography>
                    <Typography variant='caption'
                                gutterBottom
                                align='right'
                                color={(this.state.showErrors === false
                                    || this.state.error.text === false)
                                    ? 'initial' : 'error'}>
                        Using {this.state.words} of
                        <br/>
                        {this.props.sdata[this.state.size].words} words
                    </Typography>
                    {
                        (this.state.showErrors === false
                            || this.state.error.text === false)
                            ? (<div/>)
                            : (<Typography variant='caption'
                                           gutterBottom
                                           align='right'
                                           color='error'>
                                {this.state.error.text}
                            </Typography>)
                    }
                </Grid>
            </Hidden>

            {/* This is the Message Box */}

            <Grid item
                  xs={12}
                  sm={10}>

                <TextField
                    id='message'
                    label='Your Message'
                    multiline
                    value={this.state.text}
                    onChange={this.handleChange('text')}
                    style={{width: '95%'}}
                />

            </Grid>

            <Hidden smUp>
                <Grid item>
                    <Typography variant='caption'
                                gutterBottom
                                color={(this.state.showErrors === false
                                    || this.state.error.text === false)
                                    ? 'initial' : 'error'}>
                        Using {this.state.words} of {this.props.sdata[this.state.size].words} words
                    </Typography>
                </Grid>

                <Grid item
                    // alignContent='center'
                    // alignItems='center'
                      align='center'>
                    {
                        (this.state.showErrors === false || this.state.error.text === false)
                            ? (<div/>)
                            : (<Typography variant='caption'
                                           gutterBottom
                                           align='center'
                                           color='error'
                                           style={{maxWidth: '85%'}}>
                                {this.state.error.text}
                            </Typography>)
                    }
                </Grid>
            </Hidden>
        </Grid>;
    }

    imageUploadSection(direction) {
        return <Grid container
                     direction={direction}
                     justify='space-between'>
            <Hidden xsDown>
                <Grid item sm={2}>
                    <Typography variant='body2'
                                align='right'
                                color={(this.state.showErrors === false
                                    || this.state.error.photos === false)
                                    ? 'initial' : 'error'}>
                        Photos
                    </Typography>

                    <Typography variant='caption'
                                align='right'
                                gutterBottom
                                color={(this.state.showErrors === false
                                    || this.state.error.photos === false)
                                    ? 'initial' : 'error'}>
                        Using {this.state.images.length} of {this.props.sdata[this.state.size].photos} photos
                    </Typography>
                    {
                        (this.state.showErrors === false
                            || this.state.error.photos === false)
                            ? (<div/>)
                            : (<Typography variant='caption'
                                           gutterBottom
                                           align='right'
                                           color='error'>
                                <br/>
                                {this.state.error.photos}
                            </Typography>)
                    }
                </Grid>
            </Hidden>

            {/* Upload and display section */}
            <Grid item
                  xs={12} sm={10}>
                <Grid container
                      justify='space-between'
                      spacing={1}>
                    {this.state.images.map(key => (
                        <Grid item
                              key={key}
                              xs={6}>
                            <Card style={{
                                margin: 10
                            }}>
                                <CardContent>
                                    {this.state.imageFiles[key].type === 'application/pdf'
                                        ? (
                                            <Document file={this.state.imageSrcToShow[key]}
                                                      noData={'Loading PDF'}>
                                                <Page pageNumber={1}
                                                      width={170}/>
                                            </Document>
                                        ) : (
                                            <img src={this.state.imageSrcToShow[key]}
                                                 style={{
                                                     width: '100%',
                                                     height: '100%',
                                                     objectFit: 'contain',
                                                 }}
                                                 alt='uploaded'/>
                                        )
                                    }

                                    <LinearProgress variant='determinate'
                                                    value={this.state.imageProgress[key]}/>

                                    {key in this.state.specialInstructions ?
                                        (<TextField
                                            id="textarea"
                                            name="special-instructions"
                                            label="Special Instructions"
                                            multiline
                                            value={this.state.specialInstructions[key]}
                                            onChange={this.handleSiChange(key)}
                                            style={{width: '100%'}}
                                        />) : (<div/>)
                                    }

                                    {key in this.state.imageErrors
                                        ? (<Typography variant='caption'
                                                       align='center'
                                                       justify='center'
                                                       color='error'>
                                            {this.state.imageErrors[key]}
                                        </Typography>)
                                        : (<span/>)}

                                    {key in this.state.imageWarnings
                                        ? (<Typography variant='caption'
                                                       align='center'
                                                       justify='center'
                                                       color='error'>
                                            {this.state.imageWarnings[key]}
                                        </Typography>)
                                        : (<span/>)}

                                </CardContent>

                                <CardActions>

                                    {!(key in this.state.specialInstructions)
                                        ? (<Button size="small"
                                                   color="primary"
                                                   name='notes-button'
                                                   onClick={() => this.addSpecialInstructions(key)}>
                                            Add Notes
                                        </Button>)
                                        : (<div/>)
                                    }

                                    <Button size="small"
                                            color="secondary"
                                            onClick={() => this.removeImage(key)}>
                                        Remove Photo
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}

                    <Grid item xs={6}>
                        <Dropzone
                            onDrop={acceptedFiles => this.onDrop(acceptedFiles)}
                            ref={dropzoneRef}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}
                                         style={{
                                             // width: '95%',
                                             height: 80,
                                             border: 'dashed 1px',
                                             margin: 10,
                                         }}>
                                        <input {...getInputProps()}/>
                                        <Typography variant='body1'
                                                    textalign='center'>
                                            <Hidden xsDown>
                                                        <span>Drag photo here or
                                                            <br/>
                                                            click to browse for a file.
                                                        </span>
                                            </Hidden>
                                            <Hidden smUp>
                                                Tap to select a photo.
                                            </Hidden>
                                        </Typography>
                                    </div>
                                </section>
                            )}

                        </Dropzone>
                    </Grid>
                </Grid>
            </Grid>

            <Hidden smUp>
                <Grid item
                      xs={12}>
                    <Typography variant='caption'
                                align='center'
                                color={(this.state.showErrors === false
                                    || this.state.error.photos === false)
                                    ? 'initial' : 'error'}>
                        Using {this.state.images.length} of {this.props.sdata[this.state.size].photos} photos
                    </Typography>
                </Grid>
            </Hidden>

            <Hidden smUp>
                <Grid item
                      xs={12}
                      align='center'>
                    {
                        (this.state.showErrors === false || this.state.error.photos === false)
                            ? (<div/>)
                            : (<Typography variant='caption'
                                           gutterBottom
                                           align='center'
                                           color='error'
                                           style={{maxWidth: '85%'}}>
                                {this.state.error.photos}
                            </Typography>)
                    }
                </Grid>
            </Hidden>
        </Grid>;
    }
}

export default Personalization;