function arrayToEnglish(arr) {
    let len = arr.length;

    if (len === 0) {
        return '';
    }
    else if (len === 1) {
        return arr[0];
    }
    else if (len === 2) {
        return arr[0] + ' and ' + arr[1];
    }
    else {
        return arr.slice(0, -1).join(', ') + ', and ' + arr[-1];
    }
}

export const supportedImageTypes = ['image/jpeg', 'application/pdf'];
export const supportedImageTypesStr = arrayToEnglish(supportedImageTypes.map(t => t.split('/')[1].toUpperCase()));

export function ImageQualityCheck(image) {
    let error = null,
        warning = null,
        imageMinFileSize = 100 * 1000; // 100 Kb;

    if (!supportedImageTypes.includes(image.type)) {
        error = image.name + ' is an image type that isn\'t supported. ' +
            'Only ' + supportedImageTypesStr + ' files are supported.';
    } else if (image.size < imageMinFileSize) {
        warning = 'This photo file is of low quality and may not look good in print. ' +
            'Please upload a higher-quality photo. Read the guidelines below for help.';
    }

    return {
        error: error,
        warning: warning,
    };
}

