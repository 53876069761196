import React, {Component} from 'react';
import './App.css';
import Introduction from './Introduction.js';
import Info from './Info.js';
import Personalization from './Personalization.js';
import Review from './Review.js';
import Payment from './Payment.js';
import Done from './Done.js';
import 'typeface-roboto';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

class App extends Component {
    constructor(props) {
        super(props);
        this.firstCard = 0;
        this.finalCard = 5;

        firebase.initializeApp(this.firebaseConfig);

        let firestore = firebase.firestore();

        this.state.firebase = {
            dbRef: firestore,
            storageRef: firebase.storage().ref(),
        };
    }

    state = {
        card: 0,
        submissionData: {
            finalized: false,
            paid: false,
            specialInstructions: {},
            latefee: 0,
            size: undefined,
            images: [],
            studentName: '',
            purchaserName: '',
            email: '',
            text: '',
            highCard: 0,
            test: false,
        },
        schoolKey: null,
        imageFiles: {},
        imageSrcToShow: {},
        firebase: {},
        schoolData: {
            bookname: '',
            name: '',
            city: '',
            state: '',
            country: '',
        },
        thanks: undefined,
        welcome: undefined,
    };

    steps = [
        'Information',
        'Personalize',
        'Review',
        'Pay',
        'Done',
    ];

    firebaseConfig = {
        apiKey: 'AIzaSyDNazwSCrM5jp2ieYGQox1ABsz28Jkh8NA',
        authDomain: 'yerd-ly-firebase.firebaseapp.com',
        databaseURL: 'https://yerd-ly-firebase.firebaseio.com',
        projectId: 'yerd-ly-firebase',
        storageBucket: 'yerd-ly-firebase.appspot.com',
        messagingSenderId: '907855662856'
    };

    nextCard() {
        if (this.state.card === this.finalCard)
            return;

        this.initializeAdIfNeeded();

        this.setState({
            card: this.state.card + 1,
        }, () => {
            window.scrollTo(0, 0)
        });
    };

    lastCard() {
        if (this.state.card === this.firstCard)
            return;

        this.setState({
            card: this.state.card - 1,
        }, () => {
            window.scrollTo(0, 0)
        });
    };

    updateSub(data) {
        let subData = this.state.submissionData;

        Object.keys(data).forEach(key => {
            subData[key] = data[key];
        });

        if (!Date.now) {
            Date.now = function () {
                return new Date().getTime();
            }
        }

        // This returns milliseconds, but we really want seconds.
        let ms_now = Date.now();
        subData.time = Math.floor(ms_now / 1000);

        // Record highest-obtained card.
        subData.highCard = Math.max(subData.highCard, this.state.card);

        this.setState({
            submissionData: subData,
        });

        this.state.firebase.submissionDocRef.update(subData);
        console.log('Saved to Firebase');
    };

    updateImageFiles(imageFiles, imageSrcToShow) {
        this.setState({
            imageFiles: imageFiles,
            imageSrcToShow: imageSrcToShow,
        });
    };

    componentDidMount() {
        this.setState({
            submissionData: {
                ...this.state.submissionData,
                userAgent: navigator.userAgent,
            },
        });
    }

    componentWillMount() {
        // Get URL parameters
        let pathname = window.location.pathname,
            vsplit = pathname.split('/');

        console.debug(pathname);
        console.debug(vsplit);

        let schoolKey = null;
        if (vsplit[1] === '') {
            // do nothing
            return;
        } else if (vsplit.length > 1) {
            schoolKey = vsplit[1].toLowerCase();
            console.debug(schoolKey);
        }

        this.setState({
            schoolKey: schoolKey,
            submissionData: {
                ...this.state.submissionData,
                test: (schoolKey === 'testschool'),
            },
        });

        console.log(schoolKey);

        // Get background image
        let bgRef = 'school-site-resources/' + schoolKey + '/bg.jpg';
        this.state.firebase.storageRef.child(bgRef).getDownloadURL()
            .then(url => {
                document.body.style.backgroundImage = 'url(' + url + ')';
            });

        // Initialize Firebase entry
        this.state.firebase.dbRef
            .collection('schools')
            .doc(schoolKey)
            .get()
            .then(doc => {
                let schoolData = doc.data();

                this.setState({
                    schoolData: schoolData,
                });

                // Determine default size and set it
                let sd = this.state.submissionData;
                sd['size'] = schoolData.size_default;

                // Determine if a late fee is applicable
                if (!Date.now) {
                    Date.now = function () {
                        return new Date().getTime();
                    }
                }
                let ms_now = Date.now(),
                    now = Math.floor(ms_now / 1000);

                // Use the most expensive applicable fee
                sd.latefee = schoolData.latefees
                    .reduce((acc, lf) => {
                        if (lf.date < now)
                            return Math.max(acc, lf.fee);
                        return acc;
                    }, 0);

                this.setState({
                    submissionData: sd,
                });

                let fb = this.state.firebase;

                // Get sample image URLs
                schoolData.sizes.forEach(size => {
                    fb.storageRef
                        .child('school-site-resources/' + schoolKey + '/' + size + '.jpg')
                        .getDownloadURL()
                        .then(url => {
                            this.setState({
                                schoolData: {
                                    ...this.state.schoolData,
                                    [size]: {
                                        ...this.state.schoolData[size],
                                        sample: url,
                                    },
                                },
                            });
                        });
                });

                // Get welcome URLs
                if (schoolData.welcome !== undefined) {
                    fb.storageRef
                        .child('school-site-resources/' + schoolKey + '/' + schoolData.welcome.image)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({
                                welcome: url,
                            });
                        });
                }

                // Get thanks image URLs
                if (schoolData.thanks !== undefined) {
                    this.setState({
                        thanks: [],
                    }, () => {
                        schoolData.thanks.forEach(thanks => {
                            fb.storageRef
                                .child('school-site-resources/' + schoolKey + '/' + thanks)
                                .getDownloadURL()
                                .then(url => {
                                    this.setState({
                                        thanks: this.state.thanks.concat([url]),
                                    });
                                });
                        });
                    });

                }
            });

    }

    initializeAdIfNeeded() {
        let fb = this.state.firebase;

        // Already initialized?
        if (fb.submissionDocRef !== undefined && fb.imageStorageRef !== undefined)
            return;

        // No? Then let's initialize.
        let submissionDocRef = fb.dbRef
                .collection('submissions')
                .doc(this.state.schoolKey)
                .collection(this.state.schoolData.current_year)
                .doc(),
            submissionDocRefId = submissionDocRef.id;

        submissionDocRef
            .set(this.state.submissionData)
            .then(() => {
                let imageStorageRef = fb.storageRef
                    .child('submissions/' + this.state.schoolKey
                        + '/' + this.state.schoolData.current_year
                        + '/' + submissionDocRefId);

                this.setState({
                    submissionId: submissionDocRefId,
                    firebase: {
                        ...this.state.firebase,
                        submissionDocRef: submissionDocRef,
                        imageStorageRef: imageStorageRef,
                    },
                }, () => {
                    console.log('Submission initialized and ready: '
                        + submissionDocRefId);
                });
            });

    }

    render() {
        return (
            <div className='App'>
                <Grid container
                      justify='center'
                      alignItems='center'>

                    <Card>
                        {(this.state.card !== 0)
                            ? (
                                <Stepper activeStep={this.state.card - 1}
                                         alternativeLabel>
                                    {this.steps.map(label => {
                                        return (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            )
                            : (<div/>)
                        }

                        {(this.state.card === 0)
                            ? (
                                <Introduction nextCard={() => this.nextCard()}
                                              schoolData={this.state.schoolData}
                                              welcomeImg={this.state.welcome}
                                />)
                            : (<div/>)
                        }
                        {(this.state.card === 1)
                            ? (
                                <Info lastCard={() => this.lastCard()}
                                      nextCard={() => this.nextCard()}
                                      updateSub={(data) => this.updateSub(data)}
                                      submissionData={this.state.submissionData}
                                />)
                            : (<div/>)
                        }
                        {(this.state.card === 2)
                            ? (
                                <Personalization lastCard={() => this.lastCard()}
                                                 nextCard={() => this.nextCard()}
                                                 updateSub={(data) => this.updateSub(data)}
                                                 sdata={this.state.schoolData}
                                                 submissionData={this.state.submissionData}
                                                 imageFiles={this.state.imageFiles}
                                                 imageSrcToShow={this.state.imageSrcToShow}
                                                 updateImageFiles={
                                                     (imageFiles, imageSrcToShow) =>
                                                         this.updateImageFiles(imageFiles, imageSrcToShow)}
                                                 imageStorageRef={this.state.firebase.imageStorageRef}
                                />)
                            : (<div/>)
                        }
                        {(this.state.card === 3)
                            ? (
                                <Review lastCard={() => this.lastCard()}
                                        nextCard={() => this.nextCard()}
                                        submissionData={this.state.submissionData}
                                        schoolData={this.state.schoolData}
                                        imageFiles={this.state.imageFiles}
                                        imageSrcToShow={this.state.imageSrcToShow}
                                />)
                            : (<div/>)
                        }
                        {(this.state.card === 4)
                            ? (
                                <Payment
                                    price={this.state.schoolData[this.state.submissionData.size].price}
                                    latefee={this.state.submissionData.latefee}
                                    product={this.state.schoolData[this.state.submissionData.size].name}
                                    schoolKey={this.state.schoolKey}
                                    size={this.state.submissionData.size}
                                    description={
                                        'Senior ad for ' +
                                        this.state.submissionData.studentName +
                                        ' (' + this.state.schoolKey + ')'
                                    }
                                    lastCard={() => this.lastCard()}
                                    nextCard={() => this.nextCard()}
                                    dbRef={this.state.firebase.dbRef}
                                    email={this.state.submissionData.email}
                                    updateSub={(data) => this.updateSub(data)}
                                    test={this.state.submissionData.test}
                                    submissionId={this.state.submissionId}
                                />)
                            : (<div/>)
                        }
                        {(this.state.card === 5)
                            ? (
                                <Done
                                    schoolData={this.state.schoolData}
                                    thanks={this.state.thanks}
                                />)
                            : (<div/>)
                        }

                        {this.state.card > 0
                            ? (<Typography gutterBottom
                                           style={{paddingBottom: 10}}>
                                For support, please contact
                                <br/>
                                {this.state.schoolData.support_name} (
                                <a href={'mailto:' + this.state.schoolData.support_email}>
                                    {this.state.schoolData.support_email}
                                </a>)
                            </Typography>)
                            : (<div/>)
                        }
                    </Card>

                </Grid>
            </div>
        );
    }
}

export default App;
