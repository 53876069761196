import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import StripeCheckout from 'react-stripe-checkout';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
    card: {
        minWidth: 275,
        maxWidth: 1000,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    pos: {
        marginBottom: 12,
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
    table: {
        minWidth: 100,
        maxWidth: 200,
        width: 150,
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

class Payment extends Component {
    constructor(props) {
        super(props);
        this.classes = props;

        this.onToken = this.onToken.bind(this);
        this.pollTx = this.pollTx.bind(this);

        // Calculate the payment table
        let charges = [];
        charges.push([this.props.product, this.props.price]);

        if (this.props.latefee > 0)
            charges.push(['Late Fee', this.props.latefee]);

        let stripeKey = null;
        if (this.props.test) {
            console.log('TEST mode');
            stripeKey = 'pk_test_gwNxVLDvVyytZDR3mlNyqz7f';
        }
        else {
            stripeKey = 'pk_live_bhbKREUSVQjyL3aEcXTEJahS';
        }

        this.state = {
            charges: charges,
            paid: false,
            token: null,
            txState: null,
            stripeKey: stripeKey,
        };
    }

    onToken(token) {
        this.setState({
            token: token,
            txState: 'token',
        });
        console.debug(token);

        let obj = {
            token: token.id,
            schoolkey: this.props.schoolKey,
            size: this.props.size,
            latefee: this.props.latefee,
            description: this.props.description,
            test: this.props.test,
        };

        fetch('https://us-central1-yerd-ly-firebase.cloudfunctions.net/charge',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            })
            .then(response => {
                console.debug(response);

                return response.json();
            })
            .then(this.pollTx);
    }

    pollTx(res) {
        let txId = res.id,
            txDoc = this.props.dbRef.collection('transactions').doc(txId);

        this.props.updateSub({
            txId: txId,
        });

        console.info('txId: ' + txId);

        txDoc.onSnapshot(doc => {
            let state = doc.data().status;

            this.setState({
                txState: state,
                paid: state === 'success',
            });

            if (state === 'success') {
                this.props.updateSub({
                    paid: this.props.latefee + this.props.price,
                    finalized: true,
                });

                // Trigger confirmation email

                fetch('https://us-central1-yerd-ly-firebase.cloudfunctions.net/email_confirm',
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            schoolkey: this.props.schoolKey,
                            submissionId: this.props.submissionId,
                        }),
                    })
                    .then(response => {
                        console.debug('Received response from email confirmation request');
                        console.debug(response);
                    });

                this.props.nextCard();
            }

            if (state === 'stripe_error') {
                this.setState({
                    stripe_error: doc.data().stripe_error.message,
                });
            }
            console.debug(state);
        });
    }

    last() {
        this.props.lastCard();
    }

    render() {
        return (
            <CardContent id='payment'>
                <Typography variant='h5' gutterBottom>
                    Payment
                </Typography>

                <Grid container
                      className={this.classes.root}
                      justify='center'
                      direction='column'
                      alignItems='center'>
                    <Grid item
                          style={{
                              width: 250,
                          }}>
                        <Table>
                            <TableBody>
                                {
                                    this.state.charges.map(charge => {
                                        return (
                                            <TableRow key={charge}>
                                                <TableCell>{charge[0]}</TableCell>
                                                <TableCell>${charge[1]}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                <TableRow>
                                    <TableCell><b>Total</b></TableCell>
                                    <TableCell>${this.props.price + this.props.latefee}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item
                          xs={12}
                          style={{
                              width: 250,
                              paddingTop: 20,
                              paddingBottom: 20,
                          }}>

                        <StripeCheckout
                            token={this.onToken}
                            stripeKey={this.state.stripeKey}
                            name='yerd.ly'
                            description={this.props.description}
                            // image={}
                            amount={(this.props.price + this.props.latefee) * 100}
                            currency='USD'
                            locale='auto'
                            zipCode={true}
                            allowRememberMe={false}
                            email={this.props.email}
                            disabled={
                                this.state.token !== null
                                && this.state.txState !== 'stripe_error'
                            }
                        >
                            <Button variant='contained'
                                    color='primary'
                                    className={this.classes.button}
                                    disabled={
                                        this.state.token !== null
                                        && this.state.txState !== 'stripe_error'
                                    }
                                    id='paybutton'>
                                Pay ${this.props.price + this.props.latefee}
                            </Button>
                        </StripeCheckout>
                    </Grid>

                    <Grid item
                          xs={12}
                          style={{
                              width: 300,
                          }}>
                        {
                            (['token', 'init', 'stripe', 'running', 'stripe_error'].includes(this.state.txState))
                                ? (
                                    <Grid
                                        container
                                        alignItems='center'
                                        direction='row'
                                        justify='center'
                                        spacing={10}
                                    >
                                        <Grid item>
                                            {(this.state.txState === 'stripe_error')
                                                ? (
                                                    <Icon
                                                        color='error'
                                                        style={{fontSize: 36}}
                                                        id='card_error'
                                                    >
                                                        credit_card
                                                    </Icon>)
                                                : (<CircularProgress className={this.classes.progress}/>)
                                            }
                                        </Grid>
                                        <Grid item>
                                            {(this.state.txState === 'stripe_error')
                                                ? (this.state.stripe_error)
                                                : ('processing payment')
                                            }
                                        </Grid>
                                    </Grid>
                                )
                                : (<div/>)
                        }

                    </Grid>

                    <Grid item
                          xs={12}
                          style={{width: '100%'}}>
                        <Grid
                            container
                            alignItems='center'
                            direction='row'
                            justify='space-between'
                        >
                            <Button variant='contained'
                                    className={this.classes.button}
                                    disabled={this.state.paid}
                                    onClick={() => this.last()}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </CardContent>
        );
    }
}

Payment.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Payment);