import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export function PhotoGuidelines(props) {
    return (
        <Grid container
              alignItems='center'
              direction='column'
              justify='space-between'
              spacing={2}>

            <Grid item>
                <Typography variant='h6'>
                    Guidelines for Photos
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    Sometimes, photos that look good on your screen aren't high-quality enough to look
                    good in print. We want your photos to look great when we print them!
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    &bull; <b>Don't use screenshots,</b> which are low-quality.
                    <br/>
                    Please send us the original photo.
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    &bull; <b>Don't crop the photos,</b> which decreases the quality.
                    If you would like for the photo to be cropped, leave a note in the <i>
                    "Special Instructions"
                </i> field for the photo.
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    &bull; <b>Don't use photos from Facebook,</b> which are low-quality.
                    <br/>
                    Please send us the original photo.
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    &bull; Consider <b>scanning physical photos instead of taking photos of them</b>.
                    <br/>
                    You can scan your prints at a local photo or print center and submit the files here.
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    &bull; If using a photo from a website, try to download the original.
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant='body1'>
                    &bull; We prefer your photos to be JPGs, but we can use PDF.
                    <br/>
                    Every photo should have a file size of 100KB or greater.
                </Typography>
            </Grid>
        </Grid>
    );
}