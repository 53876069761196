import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AdSizeSelector from './utils/AdSizeSelector';
import {PhotoGuidelines} from './utils/PhotoGuidelines';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import 'moment-timezone';
import Moment from 'react-moment';
import red from '@material-ui/core/colors/red';

const styles = {
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
        // color: theme2.palette.text.secondary,
    },
    pos: {
        marginBottom: 12,
        // color: theme2.palette.text.secondary,
    },
};

class Introduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: props,
            learnMore: false,
            // learnMore: true,
        };

        this.ie = /*@cc_on!@ ||*/ !!document.documentMode;
        console.log(this.ie);
    }

    next() {
        this.props.nextCard();
    }

    learnMore() {
        this.setState({
            learnMore: true,
        }, () => {
            document
                .getElementById('learnMore')
                .scrollIntoView({
                    block: 'start',
                    inline: 'nearest',
                    behavior: 'smooth',
                });
        });

    }

    render() {
        let schoolData = this.props.schoolData;

        function faq() {
            return <Grid id='FAQ'
                         container
                         direction='column'
                         alignItems='center'
                         justify='center'>

                <Grid item>
                    <Typography variant='h5'
                                gutterBottom>
                        Frequently-Asked Questions
                    </Typography>
                </Grid>

                <Grid container
                      direction='column'
                      alignItems='flex-start'
                      style={{
                          paddingLeft: 10,
                          paddingRight: 10
                      }}>

                    {schoolData.faq
                        .sort((a, b) => {
                            let a_rank = schoolData.faq.indexOf(a),
                                b_rank = schoolData.faq.indexOf(b);

                            (a.order === undefined)
                                ? a_rank += 100
                                : a_rank = a.order;

                            (b.order === undefined)
                                ? b_rank += 100
                                : b_rank = b.order;

                            return a_rank - b_rank;
                        })
                        .map((qa, index) => {
                            return (
                                <Grid item key={index}>
                                    <Typography variant='body1'
                                                align='left'
                                                gutterBottom>
                                        {qa.q}
                                    </Typography>
                                    <Typography variant='body2'
                                                align='left'
                                                gutterBottom
                                                style={{paddingBottom: 20}}
                                    >
                                        {qa.a}
                                    </Typography>
                                </Grid>
                            )
                        })}
                </Grid>
            </Grid>;
        }

        function learnMore() {
            function NavButton(props) {
                return <Grid item>
                    <Button
                        color='primary'
                        variant="raised"
                        onClick={() =>
                            document
                                .getElementById(props.id)
                                .scrollIntoView({
                                    block: 'start',
                                    inline: 'nearest',
                                    behavior: 'smooth',
                                })}>
                        {props.name}
                    </Button>
                </Grid>;
            }

            return <Grid id='learnMore'
                         container
                         justify='center'
                         direction='column'>

                {schoolData.about !== undefined
                    ? (
                        <Grid item>
                            <Typography variant='h5'
                                        gutterBottom>
                                Overview
                            </Typography>
                            <Typography variant='body1'
                                        gutterBottom>
                                {schoolData.about}
                            </Typography>
                        </Grid>)
                    : <span/>
                }

                <Grid item>
                    <Typography gutterBottom>
                        For support, please contact
                        <br/>
                        {schoolData.support_name} (
                        <a href={'mailto:' + schoolData.support_email}>
                            {schoolData.support_email}
                        </a>)
                    </Typography>
                </Grid>

                {/** Navigation **/}
                <Grid item
                      style={{paddingTop: 10}}>

                    <Grid container
                          // spacing={1}
                          style={{
                              paddingBottom: 20,
                              paddingTop: 10,
                          }}
                          justify='center'
                          alignItems='center'>

                        <NavButton id='Deadlines'
                                   name='Deadlines'/>

                        <NavButton id='SamplesAndPrices'
                                   name='Samples and Prices'/>

                        <NavButton id='FAQ'
                                   name='Frequently-Asked Questions'/>

                        <NavButton id='PhotoGuidelines'
                                   name='Photo Guidelines'/>

                    </Grid>
                </Grid>

                <Grid item>
                    <hr/>
                </Grid>

                {/** Deadlines **/}
                <Grid item
                      id='Deadlines'>
                    <Typography variant='h5'
                                gutterBottom>
                        Deadlines
                    </Typography>
                </Grid>

                <Grid item
                      style={{
                          marginLeft: 'auto',
                          marginRight: 'auto'
                      }}>
                    <Table style={{width: 'auto'}}>
                        <TableBody>

                            <TableRow>
                                <TableCell>
                                    <Moment unix
                                            tz={schoolData.timezone}
                                            format="MMM D YYYY">
                                        {schoolData.open}
                                    </Moment>
                                </TableCell>
                                <TableCell>
                                    Open for Submissions
                                </TableCell>
                            </TableRow>

                            {schoolData.latefees.map((lf, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Moment unix
                                                    tz={schoolData.timezone}
                                                    format="MMM D YYYY">
                                                {lf.date}
                                            </Moment>
                                        </TableCell>
                                        <TableCell>
                                            ${lf.fee} late fee applies after this date
                                        </TableCell>
                                    </TableRow>)
                            })}

                            <TableRow>
                                <TableCell>
                                    <Moment unix
                                            tz={schoolData.timezone}
                                            format="MMM D YYYY">
                                        {schoolData.deadline}
                                    </Moment>
                                </TableCell>
                                <TableCell>
                                    Final Deadline
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </Grid>

                <Grid item
                      style={{paddingTop: 10}}>
                    <hr/>
                </Grid>

                <Grid item
                      id='SamplesAndPrices'>
                    <Typography variant='h5'
                                gutterBottom>
                        Samples and Prices
                    </Typography>
                    <Typography variant='body1'
                                gutterBottom>
                        Click on the different sizes to see samples.
                    </Typography>
                </Grid>

                <Grid item
                      style={{
                          paddingTop: 10,
                          maxWidth: 900,
                      }}>
                    <AdSizeSelector sdata={schoolData}/>
                </Grid>

                <Grid item
                      style={{paddingTop: 10}}>
                    <hr/>
                </Grid>

                {schoolData.faq !== undefined
                    ? faq.call(this)
                    : <span/>}

                <Grid item
                      id='PhotoGuidelines'
                      style={{paddingTop: 10}}>
                    <hr/>
                </Grid>

                <PhotoGuidelines/>

            </Grid>;
        }

        return (
            <CardContent id='introduction'
                         style={{
                             paddingTop: 30,
                             // padding: 40,
                         }}>

                {schoolData.bookname === ''
                    ? (
                        <Typography variant='h3' gutterBottom>
                            yerd.ly
                        </Typography>)
                    : (<Grid container
                             direction='column'
                             alignItems='center'
                             justify='center'>

                            {/*** Welcome and get started ***/}
                            <Grid item>

                                <Grid container
                                      direction='column'
                                      alignItems='center'>
                                    <Typography className={this.state.classes.title}
                                                gutterBottom
                                                variant='h6'>
                                        Senior Ads portal for
                                    </Typography>
                                    <Typography variant='h3' gutterBottom>
                                        {schoolData.bookname}
                                    </Typography>
                                    <Typography className={this.state.classes.pos}
                                                gutterBottom
                                                variant='h6'
                                                style={{marginBottom: 13}}>
                                        published by
                                    </Typography>
                                    <Typography variant='h4'
                                                gutterBottom
                                                style={{marginBottom: -1}}>
                                        {schoolData.name}
                                    </Typography>
                                    <Typography variant='subtitle1' gutterBottom>
                                        in {schoolData.city}
                                        , {schoolData.state}
                                        , {schoolData.country}.
                                    </Typography>

                                    {this.ie &&
                                    <Grid item>
                                        <Typography variant='h6'
                                                    gutterBottom
                                                    style={{color: red[700]}}>
                                            It looks like you're using Internet Explorer.
                                        </Typography>
                                        <Typography variant='h6'
                                                    gutterBottom
                                                    style={{color: red[700]}}>
                                            Please close it and visit this site
                                            <br/>
                                            with a different browser such as
                                            <br/>
                                            Edge, Chrome, Safari, or Firefox.
                                        </Typography>
                                    </Grid>
                                    }

                                    {!this.ie &&
                                    <Grid item
                                          style={{margin: 20}}
                                    >

                                        <Grid container
                                              direction='row'
                                              alignItems='center'
                                              justify='center'
                                              spacing={2}
                                        >

                                            <Grid item>
                                                <Button variant='contained'
                                                        color='primary'
                                                        style={{fontSize: 20}}
                                                        onClick={() => this.next()}
                                                        id='next'>
                                                    Get Started
                                                </Button>
                                            </Grid>

                                            {this.state.learnMore === false &&
                                            <Grid item>
                                                <Button variant='contained'
                                                        style={{fontSize: 20}}
                                                        onClick={() =>
                                                            this.learnMore()
                                                        }
                                                        id='next'>
                                                    More Info
                                                </Button>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    }

                                    {(schoolData.welcome !== undefined
                                        && this.props.welcomeImg !== undefined)
                                        ? (<Grid container
                                                 justify='center'
                                                 alignItems='center'
                                                 direction='column'
                                                 style={{
                                                     paddingTop: 20
                                                 }}>

                                            <Grid item>
                                                <img alt='Welcome'
                                                     style={{
                                                         maxWidth: '95%'
                                                     }}
                                                     src={this.props.welcomeImg}
                                                />
                                            </Grid>

                                            {!this.ie &&
                                            <Grid item
                                                  style={{
                                                      maxWidth: '80%',
                                                      // paddingBottom: 20,
                                                      paddingTop: 10,
                                                  }}
                                                  xs={8}
                                            >
                                                <Typography variant='subtitle1'>
                                                    {schoolData.welcome.message}
                                                </Typography>
                                            </Grid>
                                            }

                                        </Grid>)
                                        : (<span/>)
                                    }
                                </Grid>

                            </Grid>

                            {this.state.learnMore === false
                                ? (<span/>)
                                : (
                                    <Grid item
                                          xs={11}
                                    >
                                        <hr/>
                                        {learnMore.call(this)}
                                    </Grid>)
                            }
                        </Grid>
                    )}

            </CardContent>
        );
    }
}

Introduction.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(Introduction);