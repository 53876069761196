import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import green from '@material-ui/core/colors/green';
import {ThemeProvider, useTheme} from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {createMuiTheme} from '@material-ui/core/styles';

function AdSizeSelector(props) {
    const [size, setSize] = useState(props.sdata.size_default);

    const selectSize = key => {
        setSize(key);
        if (props.selectSize) {
            props.selectSize(key);
        }
    };

    const theme = useTheme();
    const width =
        [...theme.breakpoints.keys].reverse().reduce((output, key) => {
            const matches = useMediaQuery(theme.breakpoints.only(key));

            return !output && matches ? key : output;
        }, null) || 'xs';


    return (
        <Grid container
              direction={
                  width === 'xs'
                      ? 'column'
                      : 'row'}
              justify='center'
              wrap='nowrap'>

            <Grid item
                  xs={12}
                  sm={5}
                  style={
                    width === 'xs'
                      ? {}
                      : {maxWidth: 200}
                  }>

                <List component='nav'
                      dense={true}>

                    {props.sdata.sizes.map((key, index) => {
                        let d = props.sdata[key];

                        return (
                            <div key={key}
                                 className='photoSelectorItem'>
                                <ListItem button
                                          style={{
                                              backgroundColor:
                                                  size === key
                                                    ? green[100]
                                                    : 'transparent'
                                          }}
                                          onClick={() => selectSize(key)}>

                                    {width !== 'xs'
                                        ? (
                                            <Grid container
                                                  wrap='nowrap'
                                                  direction='row'
                                                  spacing={0}
                                                  justify='space-between'>

                                                <ListItemText
                                                    primary={d.name}
                                                    secondary={(
                                                        <span>
                                                            up to {d.photos} photos
                                                            <br/>
                                                            and {d.words} words
                                                        </span>
                                                    )}/>
                                                <ListItemText
                                                    style={{
                                                      maxWidth: 30,
                                                    }}
                                                    primary={"$" + d.price}/>

                                            </Grid>)
                                        : (
                                            <Grid container
                                                  wrap='nowrap'
                                                  direction='row'
                                                  spacing={0}
                                                  alignItems='center'>

                                                <ListItemText
                                                    primary={d.name}
                                                    style={{width: '36%'}}/>
                                                <ListItemText
                                                    style={{width: '57%'}}
                                                    secondary={
                                                        d.photos + ' photos, ' +
                                                        d.words + ' words'
                                                    }/>
                                                <ListItemText
                                                    style={{width: '7%'}}
                                                    primary={"$" + d.price}/>

                                            </Grid>)
                                    }

                                </ListItem>
                                <Divider/>
                            </div>
                        );
                    })}

                </List>
            </Grid>

            <Grid item
                  xs={12}
                  sm={7}>
                <Grid container
                      direction='column'
                      wrap='nowrap'
                      alignItems='center'>

                    <Grid item>
                        <img
                            src={props.sdata[size].sample}
                            style={{
                                maxWidth: '90%',
                            }}
                            alt='Sample Advertisement'/>
                    </Grid>

                    {props.explain
                        ? (<Grid item xs={10}>
                            <Typography variant="body2" gutterBottom>
                                {props.sdata[size].name} Example
                            </Typography>
                            <Typography variant="caption" gutterBottom>
                                The Yearbook staff will create a custom layout
                                for your ad that complements the photos
                                and message that you provide.
                            </Typography>
                        </Grid>)
                        : <span/>
                    }

                </Grid>
            </Grid>


        </Grid>
    );
}

const theme = createMuiTheme({typography: {useNextVariants: true}});

export default function UseWidth(props) {
    return (
        <ThemeProvider theme={theme}>
            <AdSizeSelector sdata={props.sdata}
                            selectSize={props.selectSize}/>
        </ThemeProvider>
    );
}