import React, {Component} from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

class Done extends Component {
    constructor(props) {
        super(props);

        let thanksIndex = null;

        if (props.thanks !== undefined) {
            thanksIndex = 0;
        }

        this.state = {
            thanksIndex: thanksIndex,
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps: ');
        console.log(nextProps);

        let thanksIndex = null;

        if (nextProps.thanks !== undefined) {
            thanksIndex = 0;
        }

        return this.setState({
            thanksIndex: thanksIndex,
        });
    }

    nextThanks() {
        let num = this.props.thanks.length,
            thanksIndex = this.state.thanksIndex + 1;

        if (thanksIndex >= num) {
            thanksIndex = 0;
        }

        this.setState({
            thanksIndex: thanksIndex,
        });
    }

    render() {
        return (
            <CardContent id='done'>
                <Typography variant="headline" gutterBottom>
                    Submission Complete!
                </Typography>

                <p>
                    Thank you for supporting {this.props.schoolData.bookname}.
                    <br/>
                    We can't wait to get started on your ad!
                </p>

                <p>
                    A confirmation email will arrive in a few minutes.
                    <br/>
                    If you don't see it, check your <b><i>spam</i></b> folder.
                </p>

                {this.props.thanksIndex !== null && this.props.thanks !== undefined
                    ? (
                        <Grid container
                              direction='column'>
                            <img src={this.props.thanks[this.state.thanksIndex]}
                                 alt='Thank you!'/>

                            <br/>

                            <Button variant='contained'
                                    onClick={() => this.nextThanks()}>
                                See Another Celebration
                            </Button>
                        </Grid>)
                    : (<div/>)
                }

                <br/>

            </CardContent>
        );
    }
}

export default (Done);