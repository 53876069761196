import React, {Component} from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentName: props.submissionData.studentName,
            purchaserName: props.submissionData.purchaserName,
            email: props.submissionData.email,
            error: {
                studentName: false,
                purchaserName: false,
                email: false,
            },
            showErrors: false,
        };
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });

        if (this.state.showErrors === true && this.hasError()) {
            this.validate();
        }
    };

    hasError() {
        return (this.state.error.studentName !== false)
            || (this.state.error.purchaserName !== false)
            || (this.state.error.email !== false);
    }

    validate() {
        let rtn = true,
            error = {
                studentName: false,
                purchaserName: false,
                email: false,
            };

        if (this.state.studentName === '') {
            error.studentName = 'Please enter your student\'s name.';
            rtn = false;
        }

        if (this.state.purchaserName === '') {
            error.purchaserName = 'Please enter a name.';
            rtn = false;
        }

        if (false === validateEmail(this.state.email)) {
            error.email = 'Please enter a valid email address.';
            rtn = false;
        }

        this.setState({
            error: error,
        });

        return rtn;
    }

    next() {
        this.setState({
            showErrors: true,
        });

        this.props.updateSub({
            studentName: this.state.studentName,
            purchaserName: this.state.purchaserName,
            email: this.state.email,
        });

        if (this.validate()) {
            this.props.nextCard();
        }
    }

    last() {
        this.props.updateSub({
            studentName: this.state.studentName,
            purchaserName: this.state.purchaserName,
            email: this.state.email,
        });
        this.props.lastCard();
    }

    render() {
        return (
            <CardContent id='info'
                         style={{
                             paddingBottom: 40,
                             paddingLeft: 30,
                             paddingRight: 30,
                             maxWidth: 275,
                         }}>
                <Grid container
                      alignItems='center'
                      direction='column'
                      justify='space-between'
                >
                    <form noValidate
                          autoComplete='off'>
                        <TextField
                            error={this.state.error.studentName !== false}
                            id='studentName'
                            label='Student Name'
                            value={this.state.studentName}
                            onChange={this.handleChange('studentName')}
                            margin='normal'
                            fullWidth
                            autoComplete='name'
                            helperText={this.state.error.studentName === false
                                ? '' : this.state.error.studentName}
                        />
                        <TextField
                            error={this.state.error.purchaserName !== false}
                            id='purchaserName'
                            label='Purchaser Name'
                            value={this.state.purchaserName}
                            onChange={this.handleChange('purchaserName')}
                            margin='normal'
                            fullWidth
                            autoComplete='name'
                            helperText={this.state.error.purchaserName === false
                                ? '' : this.state.error.purchaserName}
                        />
                        <TextField
                            error={this.state.error.email !== false}
                            id='purchaserEmail'
                            label='Purchaser Email'
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin='normal'
                            fullWidth
                            autoComplete='email'
                            helperText={this.state.error.email === false
                                ? '' : this.state.error.email}
                        />
                    </form>

                    <Grid item/>
                    <Grid item/>

                    <Grid
                        container
                        alignItems='center'
                        direction='row'
                        justify='space-between'
                    >
                        <Button variant='contained'
                                onClick={() => this.last()}>
                            Back
                        </Button>
                        <Button variant='contained'
                                color='primary'
                                onClick={() => this.next()}
                                id='next'>
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        );
    }
}

export default Info;