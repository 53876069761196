import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Document, Page} from 'react-pdf';
import {pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
    card: {
        minWidth: 275,
        maxWidth: 1000,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    pos: {
        marginBottom: 12,
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
});

class Review extends Component {
    constructor(props) {
        super(props);
        this.classes = props;
    }

    next() {
        this.props.nextCard();
    }

    last() {
        this.props.lastCard();
    }

    render() {
        return (
            <CardContent id='review'
                         style={{
                             maxWidth: 550,
                             paddingBottom: 40,
                             paddingLeft: 30,
                             paddingRight: 30,
                         }}>

                <Grid container
                      alignItems='center'
                      direction='column'
                      justify='space-between'>

                    <Grid item>
                        <Typography variant="headline" gutterBottom>
                            Review Your Ad Materials
                        </Typography>
                    </Grid>

                    <Grid container className={this.classes.root}>
                        <Grid item xs={12}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{width: '25%'}}>Student Name</TableCell>
                                        <TableCell>{this.props.submissionData.studentName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Purchaser Name</TableCell>
                                        <TableCell>{this.props.submissionData.purchaserName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Purchaser Email</TableCell>
                                        <TableCell>{this.props.submissionData.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Size</TableCell>
                                        <TableCell>{this.props.schoolData[this.props.submissionData.size].name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Price</TableCell>
                                        <TableCell>
                                            ${this.props.schoolData[this.props.submissionData.size].price + this.props.submissionData.latefee}

                                            {(this.props.submissionData.latefee > 0)
                                                ? (
                                                    <i>
                                                        <br/>
                                                        includes ${this.props.submissionData.latefee} late fee
                                                    </i>)
                                                : (<div/>)
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Message</TableCell>
                                        <TableCell style={{whiteSpace: 'pre-wrap'}}>
                                            {this.props.submissionData.text}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Photos</TableCell>
                                        <TableCell>
                                            <Grid container>
                                                {this.props.submissionData.images.map(img_id => {
                                                    return (
                                                        <Grid item
                                                              key={img_id}
                                                              sm={6}
                                                              xs={12}>
                                                            <Card>
                                                                <CardContent>
                                                                    {this.props.imageFiles[img_id].type === 'application/pdf'
                                                                        ? (
                                                                            <Document
                                                                                file={this.props.imageSrcToShow[img_id]}
                                                                                noData={'Loading PDF'}>
                                                                                <Page pageNumber={1}
                                                                                      width={150}/>
                                                                            </Document>
                                                                        ) : (
                                                                            <img
                                                                                src={this.props.imageSrcToShow[img_id]}
                                                                                alt='preview'
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    objectFit: 'contain'
                                                                                }}/>
                                                                        )
                                                                    }

                                                                    {img_id in this.props.submissionData.specialInstructions
                                                                        ? (
                                                                            <div>
                                                                                {this.props.submissionData.specialInstructions[img_id]}
                                                                            </div>)
                                                                        : (<div/>)
                                                                    }
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid
                            xs={12}
                            container
                            alignItems='center'
                            direction='row'
                            justify='space-between'
                        >
                            <Button variant='contained'
                                    onClick={() => this.last()}>
                                Back
                            </Button>
                            <Button variant='contained'
                                    color='primary'
                                    onClick={() => this.next()}
                                    id='next'>
                                Next
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </CardContent>
        );
    }
}

Review.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Review);